import axios from 'axios'
import React, { useEffect, useState } from 'react'
import './Chat.css';
import { isAuthenticated } from '../../../component/Auth/auth';
import { Sidebar } from '../../../component/adminChat/Sidebar/Sidebar';
import { AdminLayout } from '../../../layouts/AdminLayout';

export const ChatLayout = (props) => {
  const userId = props.match.params.id;
  const [users, setUsers] = useState([]);
  const [messages, setMessages] = useState([]);

  const getAllUsers = async () => {
    await axios.get('/api/users/get', {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }).then(res => {
      if (res.statusText === "OK") {
        console.log(res.data)
        const filteringUsers = res.data.filter(filUser => filUser._id !== isAuthenticated()._id);
        setUsers(filteringUsers);
        getUsersUnreadChat();
      }
    })
  }



  useEffect(() => {
    getAllUsers();
    getUsersUnreadChat();
    return () => {

    }
  }, []);


  const getUsersUnreadChat = async () => {
    await axios.get(`/api/chats/unread/ind-chat/${isAuthenticated()._id}`, {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }).then(res => {
      if (res.statusText === "OK") {
        setMessages(res.data);
        if (res.data && res.data.length > 0) {
          let filterMessages = res.data.filter(msg => msg.sender === userId);
          if (filterMessages && filterMessages.length > 0) {
            filterMessages[0].sender === userId && getAllUsers();
          }
        }
      }
      else {
        console.log('error')
      }
    })
  }

  return (
    <AdminLayout sidebar>
      <div className='chat-layout admin-chat'>
        <div className='row'>
          <div className='col-md-3 all-users-sidebar'>
            <Sidebar users={users} messages={messages} />
          </div>
          <div className='col-md-9 main-chat-body-container'>
            <div className='empty-chat'>
              <h2>Click on any user to see chats</h2>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  )
}
