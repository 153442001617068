import { DeleteOutlined } from '@ant-design/icons'
import { Avatar, Comment, Popover } from 'antd'
import axios from 'axios'
import fileDownload from 'js-file-download'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import './MessageContainer.css'

export const AdminMessagesContainer = ({ chat, user, deleteChatHandler }) => {
    const handleDownload = (url, filename) => {
        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res && res.data, filename);
                // document.location.reload();
            })
    }
    return (
        <div className={chat.sender._id === user._id ? 'sender-chat MessagesContainer' : 'receiver-chat MessagesContainer'}>
            <div>
                <button className='btn'>
                    <Comment
                        author={<h6>{chat.sender.fullName}</h6>}
                        avatar={
                            <Avatar
                            // src={chat.sender.image}
                            // alt={chat.sender.username}
                            >{chat?.sender?.firstName[0]}</Avatar>
                        }
                        content={
                            chat.type === "video" ?
                                // chat.sender._id === user._id ?
                                <div>
                                    <Popover content={<DeleteOutlined onClick={() => deleteChatHandler(chat._id, chat.sender)} />} title={false} trigger="click">
                                        <video src={chat.message} controls alt='video' type="video/mp4" />
                                    </Popover>
                                    <br />
                                    <button className='btn btn-dark' onClick={() => handleDownload(chat.message, chat.message)}>download</button>
                                </div>
                                :
                                chat.type === "image" ?
                                    <div>
                                        <Popover content={<DeleteOutlined onClick={() => deleteChatHandler(chat._id, chat.sender)} />} title={false} trigger="click">
                                            <img src={chat.message} alt='icon' />
                                        </Popover>
                                        <br />
                                        <button className='btn btn-dark mt-2' onClick={() => handleDownload(chat.message, chat.message)}>download</button>
                                    </div>
                                    :
                                    chat.sender._id === user._id ?
                                        <Popover content={<DeleteOutlined onClick={() => deleteChatHandler(chat._id, chat.sender)} />} title={false} trigger="click">
                                            <span className='text-white'>
                                                {chat.message}
                                            </span>
                                        </Popover>
                                        :
                                        <Popover content={<DeleteOutlined onClick={() => deleteChatHandler(chat._id, chat.sender)} />} title={false} trigger="click">
                                            <p className='mb-0'>
                                                {chat.message}
                                            </p>
                                        </Popover>
                        }
                        datetime={
                            <span>
                                {
                                    moment(chat.timeOfSending, 'dddd, MMMM Do YYYY, h:mm:ss a').fromNow()
                                }
                            </span>
                        }

                    />
                </button>
            </div>
        </div>
    )
}
