import { CloseOutlined, PaperClipOutlined, SendOutlined, SmileOutlined } from '@ant-design/icons';
import { Button, Input, Tooltip } from 'antd';
import { Picker } from 'emoji-mart';
import React, { useState } from 'react'
import './MessageType.css';

export const AdminMessageType = ({ showTypingMessage, submitChatHandler, UploadImage }) => {
    const [showEmojis, setShowEmojis] = useState(false);
    const [chatMessage, setChatMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState('');

    const handleEmojis = (value) => {
        setChatMessage(
            chatMessage + value.native
        )
    }


    const onTypingChange = e => {
        setChatMessage(e.target.value);
    };

    const handleImageChange = (e) => {
        setFile(
            e.target.files[0]

        )
    }

    const submitIt = () => {
        submitChatHandler(chatMessage);
        setChatMessage("");
        setShowEmojis(false)
    }

    return (
        <div className='message-type-container p-2'>
            {
                showEmojis &&
                <div className='emojis-parent'>
                    <div className='d-flex justify-content-end'>
                        <button onClick={() => setShowEmojis(false)} className='btn border'><CloseOutlined /></button>
                    </div>
                    <Picker set='apple' skin='1' onSelect={handleEmojis} />
                </div>

            }
            {
                file && file.name &&
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '23px', marginBottom: '10px', gap: '10px' }}>
                    {
                        loading ?
                            <div style={{ textAlign: 'center', paddingBottom: '10px' }}><p>Loading...</p></div>
                            :
                            <>
                                {
                                    file.name.substring(file.name.length - 3, file.name.length) === 'pdf' ||
                                        file.name.substring(file.name.length - 4, file.name.length) === 'docx' ||
                                        file.name.substring(file.name.length - 4, file.name.length) === 'doc' ||
                                        file.name.substring(file.name.length - 3, file.name.length) === 'txt' ?
                                        <p style={{ wordBreak: 'break-word' }}>{file.name}</p>
                                        :
                                        file.name.substring(file.name.length - 3, file.name.length) === 'mp4' ?
                                            <video className="profileImage" style={{ width: '200px' }} src={file !== '' ? URL.createObjectURL(file) : ''} alt=""></video>
                                            :
                                            <img className="profileImage" style={{ width: '200px' }} src={file !== '' ? URL.createObjectURL(file) : ''} alt=""></img>
                                }
                                <Button onClick={() => { UploadImage(file); setFile("") }}><SendOutlined /></Button>
                            </>
                    }
                </div>
            }
            <div className='d-flex'>
                <Input
                    onKeyPress={(e) => { showTypingMessage(); e.code === 'Enter' && submitIt() }}
                    style={{ width: '82%' }}
                    value={chatMessage}
                    placeholder="Type message here..."
                    allowClear
                    rows={1}
                    onChange={onTypingChange}
                />
                <span className='px-3 pr-0'>
                    <SmileOutlined onClick={() => setShowEmojis(!showEmojis)} />
                </span>
                <input id="actual-btn" type="file" name='file' accept=".gif,.jpg,.jpeg,.png,.doc,.docx,.mp4,.pdf" multiple onChange={handleImageChange} hidden />
                <label for="actual-btn" className='px-4'><Tooltip title='Choose File'><PaperClipOutlined /></Tooltip></label>
                <Button type='primary' loading={loading} onClick={() => submitIt()}><SendOutlined /></Button>
            </div>
        </div>
    )
}
