import axios from 'axios'
import React, { useEffect, useState } from 'react'
import './IndChat.css';
import { isAuthenticated } from '../../../component/Auth/auth';
import { Sidebar } from '../../../component/adminChat/Sidebar/Sidebar';
import { AdminLayout } from '../../../layouts/AdminLayout';
import { ChatBody } from '../../../component/adminChat/ChatBody';

export const IndChatLayout = (props) => {
  const userId = props.match.params.id;
  const [users, setUsers] = useState([]);
  const [messages, setMessages] = useState([]);
  const [success, setSuccess] = useState(false)

  const getAllUsers = async () => {
    await axios.get('/api/users/get', {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }).then(res => {
      if (res.statusText === "OK") {
        console.log(res.data)
        const filteringUsers = res.data.filter(filUser => filUser._id !== isAuthenticated()._id);
        setUsers(filteringUsers);
        getUsersUnreadChat();
      }
    })
  }



  useEffect(() => {
    getAllUsers();
    getUsersUnreadChat();
    return () => {

    }
  }, [success]);


  const getUsersUnreadChat = async () => {
    await axios.get(`/api/chats/unread/ind-chat/${isAuthenticated()._id}`, {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }).then(res => {
      if (res.statusText === "OK") {
        setMessages(res.data);
        if (res.data && res.data.length > 0) {
          let filterMessages = res.data.filter(msg => msg.sender === userId);
          if (filterMessages && filterMessages.length > 0) {
            filterMessages[0].sender === userId ? markRead() : getAllUsers();
          }
        }
      }
      else {
        console.log('error')
      }
    })
  }

  const markRead = async () => {
    await axios.post(`/api/chats/specific/mark-read`, { sender: userId, receiver: isAuthenticated()._id }).then(res => {
      if (res.status === 200) {
        console.log(res.data);
      } else {
        console.log(res);
      }
    })
  }


  const updateUserFunction = () => {
    getAllUsers();
    // markRead();
  }

  return (
    <AdminLayout sidebar>
      <div className='chat-layout admin-chat'>
        <div className='row'>
          <div className='col-md-3 all-users-sidebar'>
            <Sidebar users={users} messages={messages} />
          </div>
          <div className='col-md-9 main-chat-body-container'>
            <ChatBody receiver={userId} updateUserFunction={updateUserFunction} markRead={markRead} />
          </div>
        </div>
      </div>
    </AdminLayout>
  )
}
