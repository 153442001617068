import React from 'react'
import { Menu } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { NavLink, useLocation } from 'react-router-dom';
import './Sidebar.css'
import { logout } from '../Auth/auth';

export const AdminSideBar = () => {
    const location = useLocation();
    return (
        <div className='sidebar'>
            <Sider
                style={{
                    overflow: 'auto',
                    height: '100vh',
                }}
            >
                <h4 className='text-center py-4 text-white border-bottom'>Skygreece</h4>
                <h4 className='text-center my-4 text-white'>Dashboard</h4>
                <Menu theme="dark" mode="inline" defaultSelectedKeys={['/admin/dashboard']} selectedKeys={[location.pathname]}>
                    <Menu.Item key="/admin/chat">
                        <div className='sidebar-links'>
                            <div>
                                <i className="fas fa-message"></i>
                            </div>
                            <div>
                                <NavLink activeClassName='active' exact to='/admin/chat'>Chats</NavLink>
                            </div>
                        </div>
                    </Menu.Item>
                    <Menu.Item key="/admin/logout">
                        <div className='sidebar-links'>
                            <div>
                                <i className="fas fa-sign-out-alt"></i>
                            </div>
                            <div>
                                <a href='/login' onClick={() => { logout(() => { }) }}>
                                    Logout
                                </a>
                            </div>
                        </div>
                    </Menu.Item>
                </Menu>
            </Sider>
        </div>
    )
}
