import { useState } from 'react'
import axios from "axios"
import { ErrorMessage, SuccessMessage } from "../../component/Messages/messages";
import { CountryNames } from "../../assets/constants/places";


//signup conatiner
const SignUpContainer = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    country: "",
  });

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    setLoading(true);
    await axios.post('/api/users/signup', data).then(res => {
      setLoading(false);
      if (res.statusText === "OK") {
        SuccessMessage(res.data.successMessage);
        setTimeout(() => {
          props.handleToggle(true);

        }, 2000);
      }
      else {
        ErrorMessage(res.data.errorMessage);
      }
    })
  };


  return (
    <div className="main-container">
      <form onSubmit={submitHandler}>
        <div className="form-signup">
          <div className="each-form-field each-field-border">
            <input name="firstName" type="text" placeholder="First Name" onChange={handleChange} />
          </div>
          <div className="each-form-field each-field-border">
            <input name="lastName" type="text" placeholder="Last Name" onChange={handleChange} />
          </div>
          <div className="each-form-field each-field-border">
            <div className="custom-select login-select">
              <select name="country" className="form-select form-select-lg mb-3" aria-label=".form-select-lg example" onChange={handleChange}>
                <option value="">Select Country:</option>
                {CountryNames.map((country, i) => {
                  return (
                    <option value={country.value} key={i}>{country.label}</option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className="each-form-field each-field-border">
            <input name="email" type="email" placeholder="Email" onChange={handleChange} />
          </div>
          <div className="each-form-field">
            <input name="password" type="text" placeholder="Password" onChange={handleChange} />
          </div>
        </div>
        <div className="btn-signup-login">
          <button type='submit' className='btn-green blue-back'>Sign up</button>
        </div>
      </form>
      <div className="footer-login">
        <div className="agreement">
          By signing up, you agree to Eightydays
        </div>
        <div className="privacy">
          <a href="/">Privacy Policy and Terms of Use</a>
        </div>
      </div>
    </div>
  )
}

export default SignUpContainer