import axios from "axios";
import React, { useState } from "react";
import { Loading } from "../../component/Loading/Loading";
import { ErrorMessage, SuccessMessage } from "../../component/Messages/messages";
import './Auth.css';

export const UpdatePassword = (props) => {
  const token = props.match.params.token;
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [loading, setLoading] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (password === confirm) {
      setLoading(true);
      await axios.post(`/api/users/update/password`, { password: password, confirm: confirm, token }).then((res) => {
        setLoading(false);
        if (res.status === 200) {
          SuccessMessage(res.data.successMessage)
          props.history.push('/login');
        }
        else if (res.status === 201) {
          ErrorMessage(res.data.errorMessage)
        }
        else {
          ErrorMessage(res.data.errorMessage)
        }
      });
    } else {
      ErrorMessage("Passwords don't match")
    }
  };


  return (
    <div className='auth'>
      <div className="auth-inner-bubble-container">
        <h3 className="mb-2">Update Password</h3>
        {
          loading ?
            <Loading />
            :
            <>
              <form onSubmit={submitHandler}>
                <div className='item'>
                  <label>Password</label>
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon1"><i className="fa-solid fa-lock"></i></span>
                    <input required type="passsord" className="form-control" placeholder="Password" onChange={(e) => setPassword(e.target.value)} aria-label="Username" aria-describedby="basic-addon1" />
                  </div>
                  <div className='item mt-4'>
                    <label>Retype Password</label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon1"><i className="fa-solid fa-lock"></i></span>
                      <input required type="passsord" className="form-control" placeholder="Retype Password" onChange={(e) => setConfirm(e.target.value)} aria-label="Username" aria-describedby="basic-addon1" />
                    </div>
                  </div>
                </div>
                <button className='btn' type="submit">Submit</button>
              </form>
            </>
        }
      </div>
    </div>
  );
};
