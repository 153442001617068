import { ErrorMessage, SuccessMessage } from "../../component/Messages/messages";
import React, { useState } from 'react'
import axios from "axios";
import { setAuthentication } from "../../component/Auth/auth";
import { Link, useHistory } from "react-router-dom";
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

//login container
const LoginContainer = (props) => {
   const history = useHistory();
   const [loading, setLoading] = useState(false);
   const [data, setData] = useState({
      email: "",
      password: "",
   });

   const handleChange = (e) => {
      setData({
         ...data,
         [e.target.name]: e.target.value
      })
   }

   const submitHandler = async (e) => {
      e.preventDefault();
      window.scrollTo(0, 0);
      setLoading(true);
      await axios.post('/api/users/login', data).then(res => {
         setLoading(false);
         if (res.statusText === "OK") {
            setAuthentication(res.data, res.data.token);
            SuccessMessage(res.data.successMessage);
            history.push('/');
            document.location.reload();
         }
         else {
            ErrorMessage(res.data.errorMessage);
         }
      })
   };

   const sendGoogleToken = (tokenId) => {
      axios
         .post(`/api/users/google-login`, {
            idToken: tokenId,
         })
         .then((res) => {
            if (res.statusText === "OK") {
               setAuthentication(res.data, res.data.token);
               SuccessMessage(res.data.successMessage);
               history.push('/');
               document.location.reload();
            }
            else {
               ErrorMessage(res.data.errorMessage);
            }
         })
         .catch((error) => {
            console.log("GOOGLE SIGNIN ERROR", error.response);
         });
   };

   const sendFacebookToken = (userID, accessToken) => {
      axios
         .post(`/api/users/facebook-login`, {
            userID,
            accessToken,
         })
         .then((res) => {
            if (res.statusText === "OK") {
               setAuthentication(res.data, res.data.token);
               SuccessMessage(res.data.successMessage);
               history.push('/');
               document.location.reload();
            }
            else {
               ErrorMessage(res.data.errorMessage);
            }
         })
         .catch((error) => {
            console.log("GOOGLE SIGNIN ERROR", error.response);
         });
   };
   const responseGoogle = (response) => {
      console.log(response);
      sendGoogleToken(response.tokenId);
   };

   const responseFacebook = (response) => {
      console.log(response);
      sendFacebookToken(response.userID, response.accessToken);
   };

   return (
      <div className="main-container">
         <form onSubmit={submitHandler}>
            <div className="form-signup">
               <div className="each-form-field each-field-border">
                  <input name="email" type="email" placeholder="Email" onChange={handleChange} />
               </div>
               <div className="each-form-field">
                  <input name="password" type="text" placeholder="Password" onChange={handleChange} />
               </div>
            </div>
            <div className="btn-signup-login">
               <button type="submit" className='btn-green blue-back'>Log in</button>
            </div>
         </form>
         <div style={{ textAlign: "center", fontWeight: "bold" }} className="fonts-14">or</div>
         <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            className="w-100"
            cookiePolicy={"single_host_origin"}
         />
         <br />
         <br />
         <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            autoLoad={false}
            callback={responseFacebook}
            fields
            render={(renderProps) => (
               <button
                  onClick={renderProps.onClick}
                  className="btn btn-green btn-signup-login w-100"
                  style={{ backgroundColor: "#d55348" }}
               >
                  <div className="">
                     <i className="fab fa-facebook px-2" />
                     <span>Sign In with Facebook</span>
                  </div>
               </button>
            )}
         />
         <div className="footer-login">
            <div className="forget">
               <Link to="/forgot-password">
                  Forgot your password?
               </Link>
            </div>
            <div className="guest">
               <a href="/">Continue as guest</a>
            </div>
         </div>
      </div>
   )
}

export default LoginContainer