import { AiOutlineClockCircle } from 'react-icons/ai'
import logo from '../../assets/images/iconmain.png'
import { useState, useRef, useEffect } from "react"
import '../../assets/styles/chat.css'
import moment from 'moment';
import { io } from 'socket.io-client';
import { isAuthenticated } from "../Auth/auth"
import axios from "axios"
import { ErrorMessage, SuccessMessage } from "../Messages/messages"
import { MessageType } from './messageType'
import fileDownload from 'js-file-download'

let socket;
const FirstChat = (props) => {
    const user = isAuthenticated();
    const receiver = localStorage.getItem("greeceAdminId");
    const scrollRef = useRef();
    const [getMessage, setGetMessage] = useState([]);
    const [loading, setLoading] = useState(false);

    const getSpecificUserChat = async () => {
        await axios.post(`/api/chats/ind-chat`, { userId: user._id, receiverId: receiver }, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then(res => {
            if (res.status === 200) {
                setGetMessage(res.data.result);
            }
            else {
                ErrorMessage(res.data.errorMessage);
            }
        })
    }

    const markAllMessagesRead = async () => {
        await axios.get(`/api/chats/mark-read/${isAuthenticated()?._id}`, {
            headers: {
                "authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            if (res.status === 200) {
                // SuccessMessage(res.data.successMessage);
            }
            else {
                ErrorMessage(res.data.errorMessage);
            }
        })
    }

    const scrolltobottom = () => {
        scrollRef.current.scrollIntoView({
            block: "nearest",
            inline: "center",
            behavior: "smooth",
            alignToTop: false
        });
    }


    useEffect(() => {
        getSpecificUserChat();
        markAllMessagesRead();

        return () => {

        }
    }, [receiver]);


    let ENDPOINT;
    if (process.env.NODE_ENV === 'production') {
        ENDPOINT = "https://skygreece.herokuapp.com";
    }
    else {
        ENDPOINT = "http://localhost:8000";
    }

    const connectSocket = () => {
        socket.emit("join", { userId: user._id, username: user.username }, () => {
        });
    }

    useEffect(() => {
        socket = io(ENDPOINT)
        connectSocket();

        socket.on("Output Chat Message", async (messageFromBackend) => {
            console.log(messageFromBackend);
            if (messageFromBackend.receiver === user._id && messageFromBackend.sender._id === receiver) {
                scrolltobottom();
                setGetMessage(getMessage => [...getMessage, messageFromBackend]);
            } else if (messageFromBackend.sender._id === user._id) {
                scrolltobottom();
                setGetMessage(getMessage => [...getMessage, messageFromBackend])
            }
            if (messageFromBackend.receiver === user._id && messageFromBackend.sender._id === receiver) {
                // markRead();
            }
            socket.emit("join", { userId: user._id, username: user.username }, () => {
            });
            socket.emit('Get Online Status', { receiver, sender: user._id });
        });

        socket.on("Output Delete Chat Update", async (messageFromBackend) => {
            getSpecificUserChat();
        });

        markRead();


        return () => {
        }
    }, [ENDPOINT]);


    useEffect(() => {
        let interval = setInterval(() => {
            connectSocket();
        }, 10000);

        return () => clearInterval(interval)
    }, [])

    const submitChatHandler = async (message) => {
        message &&
            await socket.emit("Input Chat Message", {
                message: message,
                userId: user._id,
                username: user.email,
                receiver: receiver,
                nowTime: moment().format("dddd, MMMM Do YYYY, h:mm:ss a"),
                type: "text",
                origin: "0"
            });

    }


    const UploadImage = (getFile) => {
        if (!loading) {
            setLoading(true);
        }
        let data = new FormData();
        data.append('file', getFile);
        axios.post('/api/chats/upload-image', data, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then(res => {
            if (res.status === 200) {
                socket.emit("Input Chat Message", {
                    message: res.data.url,
                    cloudinary_id: res.data.id,
                    userId: user._id,
                    username: user.username,
                    receiver: receiver,
                    nowTime: moment().format("dddd, MMMM Do YYYY, h:mm:ss a"),
                    type: res.data.type,
                    origin: "0"
                });
                setLoading(false);
            }
        });
    }

    const markRead = async () => {
        await axios.post(`/api/chats/specific/mark-read`, { sender: props.match.params.id, receiver: isAuthenticated()._id }, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).then(res => {
            if (res.status === 200) {
                console.log(res.data);
            } else {
                console.log(res);
            }
        })
    }

    const handleDownload = (url, filename) => {
        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res && res.data, filename);
            })
    }
    return (
        <div className='chat-box'>
            <div className='chat-box-upper'>
                {/* <div className='ms-2' style={{ cursor: "pointer" }}>
                    <IoIosArrowBack size={20} onClick={() => props.handleRedirect("second")} />
                </div> */}
                <div style={{ width: "90%", marginLeft: "40px" }}>
                    <div className='fonts-20 fontw-500'>Skygreece</div>
                    <br />
                    <div className="row">
                        <div className="col-2 traveller-icon mt-3">
                            <img src={logo} alt="flag" height="40px" width="40px" />
                        </div>
                        <div className="col-8 mt-2" style={{ marginLeft: "10px" }}>
                            <div className='fonts-14 fontw-400'>Our usual reply time</div>
                            <div className='fonts-16 fontw-500'><AiOutlineClockCircle /> A few minutes</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='chat-box-lower'>
                <div className='chat-lower-body'>
                    {
                        getMessage && getMessage.length > 0 && getMessage.map(ch => {
                            console.log(ch);
                            return (
                                ch?.sender?._id === isAuthenticated()._id ?
                                    <div className='sent'>
                                        {
                                            ch.type === "video" ?
                                                <div>
                                                    <video src={ch.message} controls alt='video' type="video/mp4" />
                                                    <p className='msg-date mb-0'>{ch.timeOfSending}</p>
                                                    <br />
                                                    <button className='btn btn-dark w-100' onClick={() => handleDownload(ch.message, ch.message)}>download</button>
                                                </div>
                                                :
                                                ch.type === "image" ?
                                                    <div className='border'>
                                                        <button className='btn' onClick={() => handleDownload(ch.message, ch.message)}>
                                                            <img src={ch.message} alt='message' />
                                                            <p className='msg-date mb-0'>{ch.timeOfSending}</p>
                                                        </button>
                                                        <br />
                                                        <button className='btn btn-dark w-100' onClick={() => handleDownload(ch.message, ch.message)}>download</button>
                                                    </div>
                                                    :
                                                    <div>
                                                        <div className='msg'><p>{ch.message}</p></div>
                                                        <p className='msg-date'>{ch.timeOfSending}</p>
                                                    </div>
                                        }
                                    </div>
                                    :
                                    <div className='receive'>
                                        {
                                            ch.type === "video" ?
                                                <div className='border p-2'>
                                                    <video src={ch.message} controls alt='video' type="video/mp4" />
                                                    <p className='msg-date mb-0'>{ch.timeOfSending}</p>
                                                    <br />
                                                    <button className='btn btn-dark w-100' onClick={() => handleDownload(ch.message, ch.message)}>download</button>
                                                </div>
                                                :
                                                ch.type === "image" ?
                                                    <div className='border p-2'>
                                                        <button className='btn' onClick={() => handleDownload(ch.message, ch.message)}>
                                                            <img src={ch.message} alt='message' />
                                                            <p className='msg-date mb-0'>{ch.timeOfSending}</p>
                                                        </button>
                                                        <br />
                                                        <button className='btn btn-dark w-100' onClick={() => handleDownload(ch.message, ch.message)}>download</button>
                                                    </div>
                                                    :
                                                    <div>
                                                        <div className='msg'><p>{ch.message}</p></div>
                                                        <p className='msg-date'>{ch.timeOfSending}</p>
                                                    </div>
                                        }
                                    </div>
                            )
                        })
                    }
                    <div ref={scrollRef} style={{ marginTop: "100px" }}></div>
                </div>
                {
                    <MessageType submitChatHandler={submitChatHandler} UploadImage={UploadImage} />
                }
            </div>
        </div>
    )
}

export default FirstChat