import { Avatar, Comment } from 'antd'
import React from 'react'

export const AdminMessageHeader = ({ receiverHeader, typingMessage, onlineMessage }) => {
    return (
        <div className='px-2'>
            <Comment
                author={<h6 className='mt-1'>{receiverHeader.firstName} {receiverHeader.lastName}</h6>}
                avatar={
                    <Avatar>{receiverHeader?.firstName?.charAt(0)}</Avatar>
                }
                content={
                    typingMessage ?
                        <p>
                            {typingMessage}
                        </p>
                        :
                        <p>
                            {onlineMessage}
                        </p>
                }
            />
        </div>
    )
}
