export const CountryNames = [
    { value: "AUH", label: "Abu Dhabi (AUH)" },
    { value: "AMS", label: "Amsterdem (AMS)" },
    { value: "AYT", label: "Antalya (AYT)" },
    { value: "LON", label: "London (LON)" },
]

export const PlaceNames = [
    { value: "Eurotrip With Covid Vaccine Passport", label: "Eurotrip With Covid Vaccine Passport" },
    { value: "Eurotrip: Romantic Cities", label: "Eurotrip: Romantic Cities" },
    { value: "Spain", label: "Spain" },
    { value: "France", label: "France" }
]

export const PlaceNamesFilter = [
    {
        name: "Eurotrip With Covid Vaccine Passport",
        places: [
            { value: "LTN", label: "London (LON)" },
            { value: "AUH", label: "Abu Dhabi (AUH)" },
            { value: "AMS", label: "Amsterdem (AMS)" },
            { value: "AYT", label: "Antalya (AYT)" },
            { value: "ATH", label: "Athens (ATH)" },
            { value: "BCN", label: "Barcelona (BCN)" },
            { value: "BER", label: "Berlin (BER)" },
            { value: "BTS", label: "Bartislava (BTS)" }
        ]
    },
    {
        name: "Eurotrip: Romantic Cities",
        places: [
            { value: "LON", label: "London (LON)" },
            { value: "PSA", label: "Pisa (AUH)" },
            { value: "AMS", label: "Amsterdem (AMS)" },
            { value: "AYT", label: "Antalya (AYT)" },
            { value: "ATH", label: "Athens (ATH)" },
            { value: "BCN", label: "Barcelona (BCN)" },
            { value: "BER", label: "Berlin (BER)" },
            { value: "PAR", label: "Paris (PAR)" }
        ]
    },
    {
        name: "Spain",
        places: [
            { value: "BCN", label: "Barcelona (BCN)" },
            { value: "MAD", label: "Madrid (MAD)" },
            { value: "SVQ", label: "Seville (SVQ)" },
            { value: "VLC", label: "Valencia (VLC)" }
        ]
    },
    {
        name: "France",
        places: [
            { value: "BOD", label: "Bordeaux (BOD)" },
            { value: "MRS", label: "Marseille (MRS)" },
            { value: "NCE", label: "Nice (NCE)" },
            { value: "PAR", label: "Paris (PAR)" }
        ]
    }
]

export const ContryNames = [
    "Nepal",
    "India",
    "China",
    "Afganishtan"
]