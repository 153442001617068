import { CloseOutlined, PaperClipOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Input, Tooltip } from 'antd';
import React, { useState } from 'react'
import { Picker } from 'emoji-mart';
import { AiOutlineLink } from 'react-icons/ai';
import { BsEmojiSmile } from 'react-icons/bs';

export const MessageType = ({ showTypingMessage, submitChatHandler, UploadImage }) => {
    const [chatMessage, setChatMessage] = useState('');
    const [showEmojis, setShowEmojis] = useState(false);
    const [file, setFile] = useState('');


    const onTypingChange = e => {
        setChatMessage(e.target.value);
    };

    const handleImageChange = (e) => {
        setFile(
            e.target.files[0]

        )
    }

    const submitIt = () => {
        submitChatHandler(chatMessage);
        setChatMessage("");
        setShowEmojis(false)
    }

    const handleEmojis = (value) => {
        setChatMessage(
            chatMessage + value.native
        )
    }

    return (
        <div className='message-type-container p-2'>
            {
                showEmojis &&
                <div className='emojis-parent'>
                    <div className='d-flex justify-content-end'>
                        <button onClick={() => setShowEmojis(false)} className='btn border'><CloseOutlined /></button>
                    </div>
                    <Picker set='apple' skin='1' onSelect={handleEmojis} />
                </div>

            }
            {
                file && file.name &&
                <div className='file-upload'>
                    <div className='close-icon text-end'>
                        <CloseOutlined onClick={() => setFile("")} />
                    </div>
                    <div className='inner'>
                        {
                            file.name.substring(file.name.length - 3, file.name.length) === 'pdf' ||
                                file.name.substring(file.name.length - 4, file.name.length) === 'docx' ||
                                file.name.substring(file.name.length - 4, file.name.length) === 'doc' ||
                                file.name.substring(file.name.length - 3, file.name.length) === 'txt' ?
                                <p style={{ wordBreak: 'break-word' }}>{file.name}</p>
                                :
                                file.name.substring(file.name.length - 3, file.name.length) === 'mp4' ?
                                    <video className="profileImage" style={{ width: '310px' }} src={file !== '' ? URL.createObjectURL(file) : ''} alt=""></video>
                                    :
                                    <img className="profileImage" style={{ width: '310px' }} src={file !== '' ? URL.createObjectURL(file) : ''} alt=""></img>
                        }
                        <Button className='mx-3' onClick={() => { UploadImage(file); setFile("") }}><SendOutlined /></Button>
                    </div>
                </div>
            }
            <div className='chat-lower-footer'>
                <input
                    className='text-black'
                    value={chatMessage}
                    type="text"
                    placeholder="Send a message"
                    onChange={(e) => setChatMessage(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && submitIt()}
                />
                <div className='sticker-opt' style={{ zIndex: 999 }}>
                    <div><BsEmojiSmile size={20} onClick={() => setShowEmojis(!showEmojis)} /></div>
                    <span class="btn btn-file">
                        <AiOutlineLink size={20} /><input type="file" onChange={handleImageChange} />
                    </span>
                </div>
            </div>
        </div>
    )
}