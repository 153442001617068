import React from 'react'
import { Avatar, Badge, Collapse, Comment } from 'antd';
import { NavLink, useHistory } from 'react-router-dom';
import './Sidebar.css'

const { Panel } = Collapse;

export const Sidebar = ({ users, messages, updateLayout }) => {
    const navigate = useHistory();
    return (
        <div className='common-sidebar'>
            <div className='all-users'>
                <Collapse style={{ position: 'relative' }} ghost expandIconPosition='right' defaultActiveKey={'1'}>
                    <Panel header="See All" key="1">
                        {
                            users.map(user => {
                                return (
                                    <NavLink className="d-flex gap-3 align-items-center mb-4" activeClassName='user-link' onClick={() => { navigate.push(`/admin/chat/${user._id}`); document.location.reload(); updateLayout() }} to={`/chat/${user._id}`} >
                                        <Avatar style={{ backgroundColor: '#87d068', display: "flex", alignItems: "center" }}>
                                            {user.firstName[0]}
                                        </Avatar>
                                        <h6>{user.firstName} {user.lastName}</h6>
                                    </NavLink>

                                )
                            })
                        }
                    </Panel>
                </Collapse>
            </div>
        </div>
    )
}
