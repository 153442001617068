import './App.css';
import './assets/styles/common.css'
import 'emoji-mart/css/emoji-mart.css'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import ChatComponent from './component/chatcomponent';
import axios from 'axios';
import { useEffect } from 'react';
import { ErrorMessage } from './component/Messages/messages';
import { isAuthenticated } from './component/Auth/auth';
import HomePage from './pages/homepage';
import MapPage from './pages/mappage';
import PaymentPage from './pages/payment';
import LoginSignUp from './pages/loginsignup';
import UserRoute from './routes/UserRoutes';
import { ChatLayout } from './pages/admin/chat/ChatLayout';
import { IndChatLayout } from './pages/admin/IndChat/IndChat';
import { SendResetPasswordLink } from './pages/Forgot-Password/SendResetPasswordLink';
import { UpdatePassword } from './pages/Forgot-Password/UpdatePassword';
import AdminRoute from './routes/AdminRoute';


function App() {
  const getAdminId = async () => {
    await axios.get('/api/users/admin').then(res => {
      if (res.statusText === "OK") {
        console.log(res.data)
        localStorage.setItem("greeceAdminId", res.data._id)
      }
      else {
        ErrorMessage(res.data.errorMessage);
      }
    })
  }

  useEffect(() => {
    getAdminId();
    // axios.post("https://test.api.amadeus.com/v1/shopping/availability/flight-availabilities", {
    //   "originDestinations": [
    //     {
    //       "id": "1",
    //       "originLocationCode": "LGA",
    //       "destinationLocationCode": "DXB",
    //       "departureDateTime": {
    //         "date": "2022-11-30",
    //         "time": "21:15:00"
    //       }
    //     }
    //   ],
    //   "travelers": [
    //     {
    //       "id": "1",
    //       "travelerType": "ADULT"
    //     }
    //   ],
    //   "sources": [
    //     "GDS"
    //   ]
    // }, {
    //   headers: {
    //     Authorization: "Bearer ZnklOR7KSDqTGdLeAGWle38L8gZ6"
    //   }
    // }).then(function (response) {
    //   console.log(response.data);
    // }).catch(function (error) {
    //   console.error(error);
    // });

    return () => {

    }
  }, [])

  return (
    <div className="app">
      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={HomePage} />
          <Route exact path='/payment' component={PaymentPage} />
          <Route exact path='/map' component={MapPage} />
          <Route exact path='/login' component={LoginSignUp} />
          <Route exact path='/forgot-password' component={SendResetPasswordLink} />
          <Route exact path='/update/:token' component={UpdatePassword} />
          <AdminRoute exact path='/admin/chat' component={ChatLayout} />
          <AdminRoute exact path='/admin/chat/:id' component={IndChatLayout} />
        </Switch>
        {
          isAuthenticated()?.role !== 1 &&
          <ChatComponent />
        }
      </BrowserRouter>
    </div>
  );
}

export default App;
