import axios from 'axios';
import React, { useState } from 'react'
import { Loading } from "../../component/Loading/Loading";
import { ErrorMessage, SuccessMessage } from "../../component/Messages/messages";
import './Auth.css';

export const SendResetPasswordLink = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setEmail(e.target.value);
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        await axios.post(`/api/users/forgot-password`, { email }).then(res => {
            setLoading(false);
            if (res.status === 200) {
                SuccessMessage(res.data.successMessage);
            }
            else {
                ErrorMessage(res.data.errorMessage);
            }
        }).catch(error => {
            console.log(error.response);
            setLoading(false);
            ErrorMessage(`Error occured! ${error.response.data}`)
        })

    }
    return (
        <div className='auth'>
            <div className="auth-inner-bubble-container">
                <h3 className='text-dark fs-3'>Forgot Password</h3>
                {
                    loading ?
                        <Loading />
                        :
                        <>
                            <form onSubmit={submitHandler}>
                                <div className='item'>
                                    <label>Email</label>
                                    <div className="input-group">
                                        <span className="input-group-text" id="basic-addon1"><i className="fa-regular fa-envelope"></i></span>
                                        <input required name='email' type="text" className="form-control" placeholder="Email" onChange={handleChange} aria-label="Username" aria-describedby="basic-addon1" />
                                    </div>
                                </div>
                                <button className='btn' type="submit">Submit</button>
                            </form>
                        </>
                }
            </div>
        </div>
    )
}
