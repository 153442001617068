import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import './Chat.css'
import { io } from 'socket.io-client';
import { isAuthenticated } from '../Auth/auth';
import { Loading } from '../Loading/Loading';
import { AdminMessageHeader } from './MessageHeader/MessageHeader';
import { AdminMessagesContainer } from './MessagesContainer/MessagesContainer';
import { AdminMessageType } from './MessageType/MessageType';

let socket;
export const ChatBody = ({ receiver, updateUserFunction }) => {
  const user = isAuthenticated();
  const scrollRef = useRef();
  const [getMessage, setGetMessage] = useState([]);
  const [typingMessage, setTypingMessage] = useState('');
  const [onlineMessage, setOnlineMessage] = useState('');
  const [receiverHeader, setReceiverHeader] = useState({});
  const [loading, setLoading] = useState(false);

  const getSpecificUserChat = async () => {
    await axios.post(`/api/chats/ind-chat`, { userId: user._id, receiverId: receiver }, {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }).then(res => {
      if (res.status === 200) {
        setGetMessage(res.data.result);
      }
      else {
        setGetMessage('');
      }
    })
  }

  const getUserById = async () => {
    await axios.get(`/api/users/get/${receiver}`).then(res => {
      if (res.status === 200) {
        setReceiverHeader(res.data);
      } else {
        console.log(res);
      }
    })
  }



  const scrolltobottom = () => {
    scrollRef?.current?.scrollIntoView({
      block: "nearest",
      inline: "center",
      behavior: "smooth",
      alignToTop: false
    });
  }


  useEffect(() => {
    getSpecificUserChat();
    getUserById();
    // document.location.reload();

    return () => {

    }
  }, [receiver]);

  let ENDPOINT;
  if (process.env.NODE_ENV === 'production') {
    ENDPOINT = "https://timble-app.herokuapp.com/";
  }
  else {
    ENDPOINT = "http://localhost:8000";
  }

  const connectSocket = () => {
    socket.emit("join", { userId: user._id, username: user.username }, () => {

    });
  }


  useEffect(() => {
    socket = io(ENDPOINT)
    connectSocket();

    socket.emit('Get Online Status', { receiver: receiver, sender: user._id });

    socket.on("outputting typing", typing => {
      console.log(typing)
      if (typing.receiver === user._id && typing.sender === receiver) {
        setTypingMessage(typing.status);
      } else {
        setTypingMessage("");
      }
    });

    socket.on("Output Chat Message", async (messageFromBackend) => {
      console.log(messageFromBackend);
      if (messageFromBackend.receiver === user._id && messageFromBackend.sender._id === receiver) {
        setGetMessage(getMessage => [...getMessage, messageFromBackend])
      } else if (messageFromBackend.sender._id === user._id) {
        setGetMessage(getMessage => [...getMessage, messageFromBackend])
      }
      if (messageFromBackend.receiver === user._id && messageFromBackend.sender._id === receiver) {
        scrolltobottom();
        // markRead();
      }
      if (messageFromBackend.sender._id === isAuthenticated()._id) {
        scrolltobottom();
      }
      socket.emit("join", { userId: user._id, username: user.username }, () => {
      });
      socket.emit('Get Online Status', { receiver, sender: user._id });
      updateUserFunction();
    });

    socket.on("Output Delete Chat Update", async (messageFromBackend) => {
      getSpecificUserChat();
      updateUserFunction();
    });

    socket.on("Outputting Online Status", online => {
      if (online.receiverId === receiver) {
        setOnlineMessage(online.status);
      } else {

      }
    });


    return () => {
    }
  }, [ENDPOINT]);

  useEffect(() => {
    let interval = setInterval(() => {
      connectSocket();
    }, 10000);

    return () => clearInterval(interval)
  }, [])



  const submitChatHandler = async (message) => {
    setTypingMessage("");
    message &&
      await socket.emit("Input Chat Message", {
        message: message,
        userId: user._id,
        username: user.username,
        receiver: receiver,
        nowTime: moment().format("dddd, MMMM Do YYYY, h:mm:ss a"),
        type: "text"
      });
  }

  setTimeout(() => {
    setTypingMessage("");
  }, 2000);

  const showTypingMessage = async () => {
    socket.emit("The user is typing....", {
      username: user.username,
      sender: isAuthenticated()._id,
      receiver: receiver
    });
  }

  const UploadImage = (getFile) => {
    if (!loading) {
      setLoading(true);
    }
    let data = new FormData();
    data.append('file', getFile);
    axios.post('/api/chats/upload-image', data, {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }).then(res => {
      if (res.status === 200) {
        socket.emit("Input Chat Message", {
          message: res.data.url,
          cloudinary_id: res.data.id,
          userId: user._id,
          username: user.username,
          receiver: receiver,
          nowTime: moment().format("dddd, MMMM Do YYYY, h:mm:ss a"),
          type: res.data.type
        });
        setLoading(false);
      }
    });
  }

  const deleteChatHandler = async (chatId) => {
    socket.emit("Delete Chat", { chatId: chatId, receiverId: receiver });
  }



  return (
    <>
      <div>
        <div className='chat-body'>
          <div>
            <div style={{ height: '80px' }}>
              <div className='user-header user'>
                <AdminMessageHeader receiverHeader={receiverHeader} typingMessage={typingMessage} onlineMessage={onlineMessage} />
                <div className='calls-container'>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='messages-container'>
              <div style={{ marginBottom: "100px" }}>
                {
                  getMessage && getMessage.map(chat => {
                    return (
                      <div>
                        <AdminMessagesContainer chat={chat} user={user} deleteChatHandler={deleteChatHandler} />
                      </div>
                    )
                  })
                }
              </div>
              <div ref={scrollRef} style={{ marginBottom: "100px" }}></div>
              {
                loading ?
                  <Loading />
                  :
                  null
              }
            </div>
            <div>
              <AdminMessageType showTypingMessage={showTypingMessage} submitChatHandler={submitChatHandler} UploadImage={UploadImage} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
